import { useI18nCustomer } from "@/vue/apps/customer/plugins/i18n";

import filterSettingsService from "../../../services/filterSettings.service.js";

export default {
  async fetchSettings({ commit }) {
    const res = await filterSettingsService.fetchSettings();
    commit("settings", JSON.parse(res.data).data);
  },
  async storeSettings({ commit, dispatch }, settings) {
    await filterSettingsService.storeSettings(settings);

    dispatch(
      "alerts/add",
      {
        message: useI18nCustomer().t("customer.products.products_saved"),
        type: "success",
      },
      { root: true },
    );

    commit("settings", settings);
  },
  async fetchCategoriesForProducts({ commit, state }) {
    // Map to Promises
    const calls = Object.values(state.settings.products).map((product) => {
      return filterSettingsService.fetchCategoriesForProduct(
        product.product_id,
      );
    });
    // Wait for all calls to
    const results = await Promise.all(calls);

    const categories = results.map((r) => r.data).flat();

    commit("categories", categories);
  },
};
